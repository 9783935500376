import { DraftContent } from '@wix/ricos-viewer';

export const getEmptyStateContent = (title: string, label: string): DraftContent => ({
  blocks: [
    {
      key: '621er',
      text: '',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '656ir',
      text: '',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '627er',
      text: title,
      type: 'header-two',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: { textAlignment: 'center' },
    },
    {
      key: '657ir',
      text: label,
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: { textAlignment: 'center' },
    },
    {
      key: '621et',
      text: '',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '656ie',
      text: '',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
  ],
  entityMap: {},
  VERSION: '8.3.0',
});
