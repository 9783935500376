import React, { useState } from 'react';
import {
  withExperiments,
  InjectedExperimentsProps,
  useTranslation,
  useEnvironment,
  useBi,
} from '@wix/yoshi-flow-editor';
import { useSettings, useStyles } from '@wix/yoshi-flow-editor/tpa-settings/react';
import { WixRicosViewer } from '@wix/ricos-viewer';

import { pluginEmoji } from 'wix-rich-content-plugin-emoji/viewer';
import { pluginHashtag } from 'wix-rich-content-plugin-hashtag/viewer';
import { pluginMentions } from 'wix-rich-content-plugin-mentions/viewer';
import { pluginSpoiler } from 'wix-rich-content-plugin-spoiler/viewer';
import { pluginTextColor, pluginTextHighlight } from 'wix-rich-content-plugin-text-color/viewer';
import { pluginLinkButton } from 'wix-rich-content-plugin-button/viewer';

// With Loadable support
import { pluginDivider } from 'wix-rich-content-plugin-divider/loadable/viewer';
import { pluginVerticalEmbed } from 'wix-rich-content-plugin-vertical-embed/loadable/viewer';
import { pluginCollapsibleList } from 'wix-rich-content-plugin-collapsible-list/loadable/viewer';
import { pluginFileUpload } from 'wix-rich-content-plugin-file-upload/loadable/viewer';
import { pluginGallery } from 'wix-rich-content-plugin-gallery/loadable/viewer';
import { pluginGiphy } from 'wix-rich-content-plugin-giphy/loadable/viewer';
import { pluginHtml } from 'wix-rich-content-plugin-html/loadable/viewer';
import { pluginImage } from 'wix-rich-content-plugin-image/loadable/viewer';
import { pluginLink } from 'wix-rich-content-plugin-link/loadable/viewer';
import { pluginLinkPreview } from 'wix-rich-content-plugin-link-preview/loadable/viewer';
import { pluginMap } from 'wix-rich-content-plugin-map/loadable/viewer';
import { pluginPoll } from 'wix-rich-content-plugin-social-polls/loadable/viewer';
import { pluginTable } from 'wix-rich-content-plugin-table/loadable/viewer';
import { pluginVideo } from 'wix-rich-content-plugin-video/loadable/viewer';
import { pluginAudio } from 'wix-rich-content-plugin-audio/loadable/viewer';

import { truncateContent } from 'ricos-content/libs/truncateContent';
import settingsParams from '../settingsParams';
import { Card } from './Components/Card';
import { ExpandCollapseButton } from './Components/ExpandCollapseButton';
import { CONTENT_QUESTION_OPTIONS } from '../consts';
import { getEmptyStateContent } from './getEmptyStateContent';
import { st, classes } from './Widget.st.css';
import { getTheme } from './theme';
import { WidgetProps } from '../types';

export default withExperiments<InjectedExperimentsProps & WidgetProps>(
  ({
    experiments,
    className,
    content,
    host,
    skipThumbnail,
    petri_ovr = '',
    locale,
    isMobile = false,
    seoSettings,
    instance,
    metaSiteId,
  }) => {
    const [isCollapsed, toggleIsCollapsed] = useState(true);
    const { isViewer } = useEnvironment();
    const { t } = useTranslation();
    const settings = useSettings();
    const styles = useStyles();
    const logger = useBi();

    const isEmptyContent = !content;

    if (isEmptyContent && isViewer) {
      return null;
    }

    let normalizedContent = !content
      ? getEmptyStateContent(t('app.content.emptyStateTitle'), t('app.content.emptyStateLabel'))
      : content;

    if (petri_ovr) {
      petri_ovr.split(';').forEach(override => {
        const [exp, val] = override.split(':');
        if (exp && val) {
          experiments.add({ [exp]: val });
        }
      });
    }

    const shouldTruncate =
      settings.get(settingsParams.displayContent) !== CONTENT_QUESTION_OPTIONS.ALL && isCollapsed;

    if (shouldTruncate) {
      const blocksCount = settings.get(settingsParams.numBlocksToShow);
      normalizedContent = truncateContent(normalizedContent, { blocksCount }).content;
    }

    return (
      <div
        className={st(
          classes.root,
          { isMobile: experiments.enabled('specs.wixRicos.mobileSettingsOOI') && isMobile },
          className,
        )}
        data-hook="ricosviewer-wrapper"
      >
        <Card displayAllContent={!shouldTruncate}>
          <WixRicosViewer
            theme={getTheme(styles, host.id)}
            plugins={[
              pluginCollapsibleList(),
              pluginDivider(),
              pluginEmoji(),
              pluginGiphy(),
              pluginHashtag(),
              pluginImage(),
              pluginGallery(),
              pluginVideo(),
              pluginAudio(),
              pluginFileUpload(),
              pluginLink(),
              pluginMap(),
              pluginMentions(),
              pluginTable(),
              pluginTextColor(),
              pluginTextHighlight(),
              pluginHtml(),
              pluginLinkPreview(),
              pluginVerticalEmbed({ slimLayout: host.dimensions.width <= 500 }),
              pluginSpoiler(),
              pluginPoll(),
              pluginLinkButton(),
            ]}
            biSettings={{
              consumer: 'ricos-ooi-viewer',
              platform: 'Livesite',
              logger,
            }}
            content={normalizedContent}
            wixExperiments={experiments.all()}
            locale={locale}
            isMobile={isMobile}
            seoSettings={seoSettings}
            instance={instance}
            {...(metaSiteId && { iframeSandboxDomain: `https://${metaSiteId}.filesusr.com` })}
          />
        </Card>
        {!isEmptyContent &&
          settings.get(settingsParams.displayContent) === CONTENT_QUESTION_OPTIONS.WITH_EXPAND && (
            <ExpandCollapseButton isCollapsed={isCollapsed} toggleIsCollapsed={toggleIsCollapsed} />
          )}
      </div>
    );
  },
);
